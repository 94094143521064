import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Antd from "ant-design-vue";
import "./assets/mz-antd.less";
import "./assets/base.css";
import dayjs from "dayjs";
import { handleTree, handleTreeData, handleMultiply } from "@/utils/tools";
import permission from "@/directive/permission";

const app = createApp(App);
// 全局组件挂载
app.config.globalProperties.$dayjs = dayjs;
app.config.globalProperties.handleTree = handleTree;
app.config.globalProperties.handleTreeData = handleTreeData;
app.config.globalProperties.handleMultiply = handleMultiply;
// 自定义指令
app.directive("permission", permission);

app.use(store);
app.use(router);
app.use(Antd);
app.mount("#app");
