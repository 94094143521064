<template>
  <a-layout>
    <a-layout-sider width="60" class="yc-sidebar-left">
      <div class="logo">
        <img :src="logo" alt="网站后台管理系统" />
      </div>
      <ul
        class="ant-menu ant-menu-root ant-menu-vertical ant-menu-inline-collapsed ant-menu-dark"
      >
        <li
          v-for="item in menuList"
          :key="item.menuId"
          class="ant-menu-item"
          :class="{
            'ant-menu-item-selected': currentMenuId === item.menuId,
          }"
          @click="
            handleToMenuUrl(
              item.children[0].menuUrl,
              item.menuId,
              item.children[0].menuId
            )
          "
        >
          <span class="ant-menu-title-content">{{ item.menuName }}</span>
        </li>
      </ul>
    </a-layout-sider>
    <a-layout>
      <a-layout-sider theme="light" width="150" class="yc-sidebar-right">
        <template v-for="item in menuList" :key="item.menuId">
          <template v-if="currentMenuId === item.menuId">
            <h1 class="title">管理系统</h1>
            <a-divider plain>{{ item.menuName }}</a-divider>
            <div style="padding: 10px">
              <ul class="ant-menu ant-menu-root ant-menu-inline ant-menu-light">
                <li
                  v-for="subitem in item.children"
                  :key="subitem.menuId"
                  class="ant-menu-item"
                  :class="{
                    'ant-menu-item-selected':
                      currentSubmenuId === subitem.menuId,
                  }"
                  @click="
                    handleToMenuUrl(
                      subitem.menuUrl,
                      item.menuId,
                      subitem.menuId
                    )
                  "
                >
                  <span class="anticon anticon-calendar ant-menu-item-icon">
                    <icon-font v-if="subitem.icon" :type="subitem.icon" />
                  </span>
                  <span class="ant-menu-title-content">
                    {{ subitem.menuName }}
                  </span>
                </li>
              </ul>
            </div>
          </template>
        </template>
      </a-layout-sider>
    </a-layout>
  </a-layout>
</template>

<script setup>
import { useRouter } from "vue-router";
import { getCurrentInstance, onBeforeMount, ref } from "vue";
import { createFromIconfontCN } from "@ant-design/icons-vue";
import { iconFontUrl } from "@/utils/constant";
import { adminMenuList } from "@/api/user";
const logo = require("@/assets/logo.png");

const IconFont = createFromIconfontCN({
  scriptUrl: iconFontUrl,
});
const { proxy } = getCurrentInstance();
const router = useRouter();
const routerMeta = router.currentRoute.value.meta;
const currentMenuId = ref(routerMeta.parentId);
const currentSubmenuId = ref(routerMeta.menuId);
const menuList = ref([]);

onBeforeMount(() => {
  adminMenuList().then((res) => {
    menuList.value = proxy.handleTree(res.data, "menuId", true);
  });
});

const handleToMenuUrl = (menuUrl, menuId, submenuId) => {
  currentMenuId.value = menuId;
  currentSubmenuId.value = submenuId;
  router.push(menuUrl);
};
</script>

<style scoped>
.yc-sidebar-left {
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
}
.yc-sidebar-right {
  margin-left: 60px;
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
}
.logo {
  padding: 10px;
}
.logo img {
  width: 40px;
  height: 40px;
}
.title {
  padding-top: 12px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item {
  color: #fff;
  padding-left: 15px;
  padding-right: 16px;
}
.ant-divider-horizontal.ant-divider-with-text {
  margin: 0;
}
.ant-menu-inline {
  border-right: 0;
}
.ant-menu-inline .ant-menu-item::after {
  border-right: 0;
}
</style>
