/**
 * 构造树型结构数据 - 屏蔽没有子类的数据
 * @param {*} data 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} removeParent 移除无children的父数据 默认 false
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 */
export function handleTree(data, id, removeParent, parentId, children) {
  let config = {
    id: id || "id",
    removeParent: removeParent || false,
    parentId: parentId || "parentId",
    childrenList: children || "children",
  };

  let childrenListMap = {};
  let nodeIds = {};
  let tree = [];

  for (let d of data) {
    let parentId = d[config.parentId];
    if (childrenListMap[parentId] == null) {
      childrenListMap[parentId] = [];
    }
    nodeIds[d[config.id]] = d;
    childrenListMap[parentId].push(d);
  }
  for (let d of data) {
    let parentId = d[config.parentId];
    if (nodeIds[parentId] == null) {
      tree.push(d);
    }
  }
  for (let t of tree) {
    adaptToChildrenList(t);
  }

  function adaptToChildrenList(o) {
    if (childrenListMap[o[config.id]] !== null) {
      o[config.childrenList] = childrenListMap[o[config.id]];
    }
    if (o[config.childrenList]) {
      for (let c of o[config.childrenList]) {
        adaptToChildrenList(c);
      }
    }
  }
  let newTree = [];
  tree.forEach((item) => {
    if (item[config.childrenList] !== undefined) {
      newTree.push(item);
    }
  });
  if (removeParent) {
    return newTree;
  } else {
    return tree;
  }
}

/**
 * 构造树型结构数据 - 保存所有数据包含没有子类数据
 * @param data 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} removeParent 移除无children的父数据 默认 false
 * @returns {*[]}
 */
export function handleTreeData(data, id, removeParent) {
  let config = {
    id: id || "id",
    removeParent: removeParent || false,
    parentId: "parentId",
    childrenList: "children",
  };

  let childrenListMap = {};
  let nodeIds = {};
  let tree = [];

  for (let d of data) {
    let parentId = d[config.parentId];
    if (childrenListMap[parentId] == null) {
      childrenListMap[parentId] = [];
    }
    nodeIds[d[config.id]] = d;
    childrenListMap[parentId].push(d);
  }
  for (let d of data) {
    let parentId = d[config.parentId];
    if (nodeIds[parentId] == null) {
      tree.push(d);
    }
  }
  for (let t of tree) {
    adaptToChildrenList(t);
  }

  function adaptToChildrenList(o) {
    if (childrenListMap[o[config.id]] !== null) {
      o[config.childrenList] = childrenListMap[o[config.id]];
    }
    if (o[config.childrenList]) {
      for (let c of o[config.childrenList]) {
        adaptToChildrenList(c);
      }
    }
  }
  let newTree = [];
  tree.forEach((item) => {
    newTree.push(item);
  });
  if (removeParent) {
    return newTree;
  } else {
    return tree;
  }
}

/**
 * 乘法精度计算
 * @param a
 * @param b
 * @returns {number}
 */
export function handleMultiply(a, b) {
  const s1 = a.toString();
  const s2 = b.toString();
  let m1 = 0;
  let m2 = 0;
  try {
    m1 += s1.split(".")[1].length;
  } catch (error) {
    console.log(error);
  }
  try {
    m2 = s2.split(".")[1].length;
  } catch (error) {
    console.log(error);
  }
  // const t1 = Number(s1) * Math.pow(10, m1);
  // const t2 = Number(s2) * Math.pow(10, m2);
  // return (t1 * t2) / Math.pow(10, m1 + m2);
  // 精度计算
  const t1 = Number(s1.replace(".", ""));
  const t2 = Number(s2.replace(".", ""));
  return (t1 * t2) / Math.pow(10, m1 + m2);
}
