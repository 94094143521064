<template>
  <div class="app-main">
    <router-view />
  </div>
</template>

<script setup></script>

<style scoped>
.app-main {
  /*min-height: calc(100vh - 60px);*/
  min-height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
  /*margin-top: 60px;*/
  /*padding: 16px;*/
  padding: 0 10px;
}
</style>
