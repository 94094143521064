const permission = {
  mounted(el, binding) {
    let permission = binding.value; // 获取到 v-permission的值
    if (permission && permission instanceof Array) {
      if (permission.length > 0) {
        // 当前拥有的权限列表
        let permissionsLocal = localStorage.getItem("permissions");
        let permissions = JSON.parse(permissionsLocal);
        const hasPermission = permissions.some((role) => {
          return permission.includes(role);
        });
        if (!hasPermission) {
          el.parentNode && el.parentNode.removeChild(el);
        }
      }
    } else {
      throw new Error(`need roles! Like v-permission="['admin','user']"`);
    }
  },
};

export default permission;
