import { createRouter, createWebHashHistory } from "vue-router";
import { isLogin } from "@/utils/auth";
import Layout from "@/layout/IndexView.vue";

const routes = [
  {
    path: "/login",
    name: "LoginView",
    component: () => import("@/views/user/LoginView.vue"),
  },
  {
    path: "/",
    name: "index",
    component: Layout,
    redirect: "/home",
    meta: { title: "首页" },
    children: [
      {
        path: "home",
        name: "HomeView",
        component: () => import("@/views/HomeView.vue"),
        meta: {
          title: "工作台",
          auth: true,
          menuId: 2,
          parentId: 1,
        },
      },
    ],
  },
  {
    path: "/template",
    name: "template",
    component: Layout,
    meta: { title: "模板" },
    children: [
      {
        path: "item",
        name: "TemplateItem",
        component: () => import("@/views/template/TemplateItem"),
        meta: {
          title: "模板管理",
          auth: true,
          menuId: 11,
          parentId: 10,
        },
      },
      {
        path: "tag",
        name: "TemplateTag",
        component: () => import("@/views/template/TemplateTag"),
        meta: {
          title: "标签管理",
          auth: true,
          menuId: 12,
          parentId: 10,
        },
      },
      {
        path: "add",
        name: "TemplateItemAdd",
        component: () => import("@/views/template/TemplateItemAdd"),
        meta: {
          title: "发布模板",
          auth: true,
          menuId: 13,
          parentId: 10,
        },
      },
      {
        path: "edit/:id",
        name: "TemplateItemEdit",
        component: () => import("@/views/template/TemplateItemEdit"),
        meta: {
          title: "编辑模板",
          auth: true,
          menuId: 14,
          parentId: 10,
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// 路由拦截
router.beforeEach((to, from, next) => {
  if (to.fullPath === "/login" && isLogin()) {
    next("/");
  } else if (to.meta.auth && !isLogin()) {
    next("/login");
  } else {
    next();
  }
});

export default router;
