import request from "../plugins/request";

/**
 * 登录
 */
export const login = (data) => {
  return request({
    url: "/admin/login",
    method: "post",
    data,
  });
};

/**
 * 修改密码
 */
export const adminChangePassword = (data) => {
  return request({
    url: "/admin/changePassword",
    method: "post",
    data,
  });
};

/**
 * 获取管理员菜单
 */
export function adminMenuList() {
  return request({
    url: "/admin/menu/list",
    method: "get",
  });
}
