import { createStore } from "vuex";

export default createStore({
  state: {
    staff: {},
    role: {},
    contentPics: [],
    service: {},
  },
  getters: {},
  mutations: {
    setStaff: (state, staff) => {
      state.staff = staff;
    },
    setRole: (state, role) => {
      state.role = role;
    },
    setContentPics: (state, contentPics) => {
      state.contentPics = contentPics;
    },
    setService: (state, service) => {
      state.service = service;
    },
  },
  actions: {
    getService({ commit }, data) {
      commit("setService", data);
    },
  },
  modules: {},
});
